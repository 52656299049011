import React from 'react';

// Local Components
import { Spacer } from 'components';

export const HeaderSectionSpacer = () => {
  return (
    <Spacer height={['22rem', null, '17.5rem', null, '19.9rem', '24.7rem']} />
  );
};
