import React from 'react';

// External Components
import { Section, Paragraph, Box } from '@thepuzzlers/pieces';

// Local Components
import { GridSpacer } from 'components';

// Assets
import { ListDotOne, ListDotTwo, ListDotThree } from 'components/svgs';
import { wrapIndex } from 'helper';
import { MarkedText } from 'components';
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Benefit = ({ id, data, children, isRightSideTextBlock }) => {
  return (
    <Section id={id}>
      <TextBlockWithList
        data={data}
        isRightSideTextBlock={isRightSideTextBlock}
      />
      <ListSpacer />
      <TabletPortraitList list={data.list} />
      {children}
    </Section>
  );
};

const TextBlockWithList = ({
  data: { description, headline, list },
  isRightSideTextBlock
}) => {
  return (
    <Box
      sx={{
        gridColumn: isRightSideTextBlock
          ? ['1/13', null, '1 / span 13', null, '14 / span 9', '15 / span 8']
          : ['1/13', null, '1 / span 13', null, '3 / span 9', '4 / span 8'],
        alignSelf: 'center'
      }}>
      <Headline headline={headline} />
      <Description description={description} />
      <List
        list={list}
        sx={{
          display: ['block', null, 'none', null, 'block', 'block']
        }}
      />
    </Box>
  );
};

const Headline = ({ headline }) => {
  return (
    <MarkedText
      as="h2"
      sx={{
        lineHeight: 1.25,
        letterSpacing: '-0.01em',
        fontSize: ['2.4rem', null, '2.8rem', null, '2.8rem', '3.2rem']
      }}
      data={convertApiHtmlText(headline.html)}
    />
  );
};

const Description = ({ description }) => {
  return (
    <Paragraph
      sx={{
        fontFamily: 'body.normal',
        lineHeight: 1.5,
        letterSpacing: '-0.01em',
        fontSize: ['1.6rem', null, '1.6rem', null, '1.6rem', '1.7rem'],
        mt: ['0.8rem', null, '1.2rem', null, '1.2rem', '1.2rem']
      }}>
      {description}
    </Paragraph>
  );
};

const TabletPortraitList = ({ list }) => {
  return (
    <List
      list={list}
      sx={{
        display: ['none', null, 'block', null, 'none', 'none'],
        gridColumn: [null, null, '1/ span 13', null, null, null],
        width: [null, null, '95%', null, null],
        // some card take so much grid colums
        gridRow: [null, null, 3, null, null],
        mt: 0
      }}
    />
  );
};

// Reused Components
const List = ({ list, sx }) => {
  return (
    <Box
      as="ul"
      sx={{
        mt: ['5.6rem', null, '4.8rem', null, '4.8rem', '6.4rem'],
        ...sx
      }}>
      {list.map((data, index) => (
        <ListItem data={data} key={index} index={index} />
      ))}
    </Box>
  );
};

const ListItem = ({ data: { text }, index }) => {
  const ListDots = [ListDotOne, ListDotTwo, ListDotThree];

  const selectListDot = (index) => {
    return ListDots[wrapIndex(ListDots.length, index)];
  };

  const SelectedListDot = selectListDot(index);

  return (
    <Box
      as="li"
      sx={{
        display: 'flex',
        alignItems: 'center',
        ':not(:first-of-type)': {
          mt: '2.4rem'
        }
      }}>
      <SelectedListDot
        sx={{
          width: ['1.1rem', null, '1.1rem', null, '1.2rem', '1.1rem'],
          color: 'primary'
        }}
      />
      <Paragraph
        as="span"
        sx={{
          fontFamily: 'body.normal',
          lineHeight: 1.35,
          letterSpacing: '-0.01em',
          fontSize: ['1.6rem', null, '1.6rem', null, '1.6rem', '1.7rem'],
          flex: 1,
          ml: ['1.6rem', null, '2.4rem', null, '2.4rem', '2.4rem']
        }}>
        {text}
      </Paragraph>
    </Box>
  );
};

// Spacer

const ListSpacer = () => {
  return (
    <GridSpacer
      height={[null, null, '4.8rem', null, null, null]}
      sx={{
        display: ['none', null, 'block', null, 'none', 'none']
      }}
    />
  );
};
