import React from 'react';

// Local Components
import { GetPaidCard as GetPaidCardDefault } from 'components';

// Data
import { useSolutionPageData } from 'sections/solution/useSolutionPageData';

export const GetPaidCard = () => {
  const {
    cards: {
      getPaidCard: { caption, description, list, price }
    }
  } = useSolutionPageData();

  return (
    <GetPaidCardDefault
      sx={{
        gridColumn: ['1/13', null, '14 / 25', null, '14/ span 8', '15/ span 7'],
        mt: ['4.8rem', null, 0, null, '4.4rem', '5rem'],
        gridRow: [null, null, 3, null, 'unset', 'unset'],
        alignSelf: 'start'
      }}
      caption={caption}
      description={description}
      list={list}
      price={price}
    />
  );
};
