import React from 'react';

// Assets
import { ProfileCard as ProfileCardDefault } from 'components';

// Data
import { useSolutionPageData } from 'sections/solution/useSolutionPageData';

export const ProfileCard = () => {
  const {
    cards: {
      profileCard: { description, image, profileImage, title }
    }
  } = useSolutionPageData();

  return (
    <ProfileCardDefault
      description={description}
      image={image}
      profileImage={profileImage}
      title={title}
      sx={{
        gridColumn: ['1/ 13', null, '14/25', null, '14/ span 9', '15/ span 8'],
        width: [null, null, null, null, null, '39.7rem']
      }}
    />
  );
};
