import React from 'react';

// Local Components
import { Spacer } from 'components';

export const BenefitSectionSpacer = ({ children }) => {
  return (
    <Spacer
      height={['29.6rem', null, '21.4rem', null, '24rem', '27.8rem']}
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      {children}
    </Spacer>
  );
};
