import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export const useSolutionPageData = () => {
  const { locale } = usePageContext();

  const data = useStaticQuery(graphql`
    query {
      en: solutionPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        cards {
          productsCatalog {
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            title
            price
            mark
          }
          flexSeedBread {
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            title
            description
            orderVolume {
              title
              list {
                text
              }
            }
            pricePerPiece {
              title
              list {
                text
              }
            }
          }
          getPaidCard {
            description
            list {
              text
            }
            caption
            price
          }
          timelineCard {
            title
            cardImage {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            postDate
            caption
            description
          }
          profileCard {
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            title
            description
            profileImage {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
          }
          chatsCard {
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            message
          }
        }
      }
      de: deSolutionPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        cards {
          productsCatalog {
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            title
            price
            mark
          }
          flexSeedBread {
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            title
            description
            orderVolume {
              title
              list {
                text
              }
            }
            pricePerPiece {
              title
              list {
                text
              }
            }
          }
          getPaidCard {
            description
            list {
              text
            }
            caption
            price
          }
          timelineCard {
            title
            cardImage {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            postDate
            caption
            description
          }

          profileCard {
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            title
            description
            profileImage {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
          }
          chatsCard {
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            message
          }
        }
      }
    }
  `);

  return data[locale];
};
