import React from 'react';

// Local Components
import { TimelineCard as BaseTimelineCard } from 'components';
import { useSolutionPageData } from 'sections/solution/useSolutionPageData';

export const TimelineCard = ({}) => {
  const {
    cards: {
      timelineCard: { caption, cardImage, description, postDate, title }
    }
  } = useSolutionPageData();

  return (
    <BaseTimelineCard
      sx={{
        gridColumn: ['1/13', null, '14/25', null, '14 / span 9', '15 / span 7'],
        alignSelf: ['start', null, 'start', null, 'start', 'center']
      }}
      caption={caption}
      cardImage={cardImage}
      description={description}
      postDate={postDate}
      title={title}
    />
  );
};
