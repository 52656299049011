import React from 'react';

// External Components
import { Box, Paragraph } from '@thepuzzlers/pieces';

// Local Components
import { AspectRatioImageContainer } from 'components';

// Data
import { useSolutionPageData } from 'sections/solution/useSolutionPageData';

// Assets
import { CardBackgroundOne } from 'components';

export const FlexseedBreadCard = () => {
  const {
    cards: {
      flexSeedBread: { description, image, orderVolume, pricePerPiece, title }
    }
  } = useSolutionPageData();

  return (
    <Box
      sx={{
        gridColumn: ['1/13', null, '15/25', null, '3 / span 8', '4 / span 7'],
        gridRow: [null, null, 3, null, 1, 1],
        p: [
          '1.6rem 1.6rem 1.6rem 1.6rem',
          null,
          '1.6rem 1.6rem 2.5rem 1.6rem',
          null,
          '1.6rem 1.6rem 3.6rem 1.6rem',
          '1.8rem 1.8rem 2.9rem 1.8rem'
        ],
        bg: 'white',
        borderRadius: 'small',
        mt: ['4.8rem', null, 0, null, 0, 0],
        position: 'relative'
      }}>
      <CardImage image={image} />
      <Title title={title} />
      <Description description={description} />
      <Pricing orderVolume={orderVolume} pricePerPiece={pricePerPiece} />
      <CardBackground />
    </Box>
  );
};

const CardImage = ({ image: { src, alt } }) => {
  return (
    <AspectRatioImageContainer
      src={src}
      alt={alt}
      aspectRatios={1.78}
      sx={{
        '& img': {
          borderRadius: 'small'
        }
      }}
    />
  );
};

const Title = ({ title }) => {
  return (
    <Paragraph
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1.35,
        letterSpacing: '-0.01em',
        fontSize: ['1.8rem', null, '1.8rem', null, '1.8rem', '1.9rem'],
        mt: ['1.6rem', null, '1.6rem', null, '1.2rem', '1.6rem']
      }}>
      {title}
    </Paragraph>
  );
};

const Description = ({ description }) => {
  return (
    <Paragraph
      sx={{
        fontFamily: 'body.normal',
        lineHeight: 1.35,
        letterSpacing: '-0.01em',
        fontSize: ['1.4rem', null, '1.3rem', null, '1.3rem', '1.4rem'],
        mt: ['0.4rem', null, '0.7rem', null, '0.7rem', '0.8rem'],
        width: ['24.9rem', null, '100%', null, '27.2rem', '29rem']
      }}>
      {description}
    </Paragraph>
  );
};

const Pricing = ({ orderVolume, pricePerPiece }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        mt: ['3.2rem', null, '2.4rem', null, '2.5rem', '2.4rem']
      }}>
      <TableColumn data={orderVolume} />
      <TableColumn data={pricePerPiece} />
    </Box>
  );
};

const TableColumn = ({ data: { title, list } }) => {
  return (
    <Box>
      <ColumnText text={title} />
      {list.map(({ text }, index) => (
        <ColumnText text={text} key={index} />
      ))}
    </Box>
  );
};

const ColumnText = ({ text }) => {
  return (
    <Paragraph
      className="__column-text"
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1,
        letterSpacing: '-0.01em',
        fontSize: ['1.4rem', null, '1.4rem', null, '1.4rem', '1.4rem'],
        '~ .__column-text': {
          mt: ['0.8rem', null, '0.9rem', null, '0.9', '1.1rem']
        },
        // the second text
        ':nth-of-type(2)': {
          mt: ['1rem', null, '1.2rem', null, '1.2rem', '1.5rem']
        }
      }}>
      {text}
    </Paragraph>
  );
};

const CardBackground = () => {
  return (
    <CardBackgroundOne
      preserveAspectRatio="none"
      sx={{
        position: 'absolute',
        width: ['36.1rem', null, '36rem', null, '41rem', '47rem'],
        top: ['2.8rem', null, '1.7rem', null, '2.7rem', '1.9rem'],
        height: [null, null, null, null, '36rem', '41.6rem'],
        left: '50%',
        transform: 'translate(-50%, 0)',
        color: 'darkBackground',
        zIndex: -1
      }}
    />
  );
};
