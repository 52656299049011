import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';
import { Helmet } from 'react-helmet';

// External Components
import { Box } from '@thepuzzlers/pieces';

// Local Components
import { Header, Benefit } from 'sections/solution';
import { useKisSolutionBuyPageData } from 'graphqlHooks';
import { CandyStickOutlined, DottedArrow, Spacer } from 'components';
import { Cta } from 'components/section';

// Assets
import thumbnail from 'assets/seo/thumbnail.png';
import { useSolutionPageData } from 'sections/solution';
import {
  BenefitSectionSpacer,
  ProductCatalogCard,
  FlexseedBreadCard,
  GetPaidCard,
  HeaderSectionSpacer
} from 'sections/solution/benefit/components';
import { Swirl, Leaf } from 'components/svgs';

// Data

const SolutionSell = () => {
  const { seo } = useSolutionPageData();
  const {
    header: cmsHeader,
    benefits: { solution_benefits }
  } = useKisSolutionBuyPageData();

  return (
    <>
      <SEO {...seo} />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:image" content={thumbnail} />

        {/* Twitter */}
        <meta property="twitter:url" content={seo.url} />
        <meta property="twitter:image" content={thumbnail} />
      </Helmet>
      <Header data={cmsHeader} ImageDecoration={ImageDecoration} />
      <HeaderSectionSpacer />
      <Box as="main">
        <Benefit data={solution_benefits[0].data} isFirstBenefit>
          <ProductCatalogCard />
        </Benefit>
        <DecoratedSpacerOne />

        <Benefit data={solution_benefits[1].data} isRightSideTextBlock>
          <FlexseedBreadCard />
        </Benefit>
        <DecoratedSpacerTwo />
        <Benefit data={solution_benefits[2].data}>
          <GetPaidCard />
        </Benefit>
        <Spacer
          height={['23.3rem', null, '28.5rem', null, '41.3rem', '33.9rem']}
        />
        <Cta />
      </Box>
      <Spacer height={['8rem', null, '13.5rem', null, '6.3rem', '9.7rem']} />
    </>
  );
};

export default injectIntl(SolutionSell);

const ImageDecoration = () => {
  return (
    <>
      <Swirl
        sx={{
          width: ['2.9rem', null, '3.9rem', null, '4.6rem', '4.6rem'],
          position: 'absolute',
          top: ['6rem', null, '10.2rem', null, '11.7rem', '16.2rem'],
          left: ['5.8rem', null, '7.8rem', null, '9.1rem', '10.8rem']
        }}
      />
      <CandyStickOutlined
        sx={{
          width: ['4.4rem', null, '6.2rem', null, '6rem', '8.6rem'],
          position: 'absolute',
          bottom: ['-4rem', null, '-5.7rem', null, '-5.3rem', '-8rem'],
          right: ['3.2rem', null, '4.6rem', null, '5.5rem', '5.4rem']
        }}
      />
    </>
  );
};

const DecoratedSpacerOne = () => {
  return (
    <BenefitSectionSpacer>
      <DottedArrow
        sx={{
          width: ['4.9rem', null, '3.5rem', null, '3.7rem', '4.9rem'],
          position: 'absolute',
          transform: 'rotate(30deg)'
        }}
      />
    </BenefitSectionSpacer>
  );
};
const DecoratedSpacerTwo = () => {
  return (
    <BenefitSectionSpacer>
      <Leaf
        sx={{
          width: ['7.6rem', null, '7.6rem', null, '7.7rem', '10rem'],
          position: 'absolute',
          transform: 'rotate(-135deg)'
        }}
      />
    </BenefitSectionSpacer>
  );
};
