import React from 'react';
import { useSolutionPageData } from 'sections/solution/useSolutionPageData';

// External Components
import { Box } from '@thepuzzlers/pieces';

// Local Components
import { ProductCatalogItem } from 'components';

// Assets
import { CardBackgroundOne } from 'components';

export const ProductCatalogCard = ({ sx }) => {
  const {
    cards: { productsCatalog }
  } = useSolutionPageData();

  return (
    <Box
      sx={{
        gridColumn: ['1/13', null, '14/25', null, '15 / span 8', '15 / span 7'],
        mt: ['4.8rem', null, 0, null, 0, '-1.2rem'],
        alignSelf: 'center',
        position: 'relative',
        ...sx
      }}>
      {productsCatalog.map(({ image, title, price, mark }, index) => (
        <ProductCatalogItem
          key={index}
          image={image}
          title={title}
          price={price}
          mark={mark}
          sx={{
            position: 'relative',
            zIndex: 1
          }}
        />
      ))}
      <CardBackgroundOne
        sx={{
          position: 'absolute',
          width: ['35.1rem', null, '36rem', null, '37.8rem', '41.6rem'],
          top: ['-3.2rem', null, '-4.3rem', null, '-4.3rem', '-4.7rem'],
          left: ['-1.6rem', null, '-1.7rem', null, '-2.1rem', '-2.5rem'],
          color: 'darkBackground'
        }}
      />
    </Box>
  );
};
