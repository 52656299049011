import React from 'react';

// External Components
import { Section, Paragraph, ResponsiveImage } from '@thepuzzlers/pieces';

// Local Components
import {
  GridSpacer,
  AspectRatioImageContainer,
  HeadlineBlock
} from 'components';

// Assets
import { convertApiHtmlText } from 'graphqlHooks/helper';
import phonePortraitBg from 'assets/solutionPage/header/phone-portrait-section-bg.svg';
import tabletPortraitBg from 'assets/solutionPage/header/tablet-portrait-section-bg.svg';
import tabletLandscapeBg from 'assets/solutionPage/header/tablet-landscape-section-bg.svg';
import desktopBg from 'assets/solutionPage/header/desktop-section-bg.svg';

export const Header = ({
  data: { description, headline, caption, image },
  ImageDecoration
}) => {
  return (
    <Section
      as="header"
      id="solution-sell__header"
      sx={{
        position: 'relative'
      }}>
      <SectionBackground />
      <TopSpacer />
      <Image image={image} ImageDecoration={ImageDecoration} />
      <TextBlock headline={headline} caption={caption} />
      <Description description={description} />
    </Section>
  );
};

const Image = ({ image: { alt, image }, ImageDecoration }) => {
  return (
    <AspectRatioImageContainer
      src={image}
      alt={alt}
      aspectRatios={1 / 1.2}
      sx={{
        position: 'relative',
        gridColumn: ['3/13', null, '12/25', null, '1/ span 11', '1/ span 10'],
        gridRow: [null, null, null, null, '2/ span 2', '2/ span 2'],
        mt: ['9.1rem', null, 0, null, 0, 0],
        zIndex: 1,
        '& img': {
          borderRadius: '0.4rem'
        }
      }}>
      {ImageDecoration && <ImageDecoration />}
    </AspectRatioImageContainer>
  );
};

const TextBlock = ({ headline, caption }) => {
  return (
    <HeadlineBlock
      sx={{
        gridColumn: [
          '1/ span 11',
          null,
          '1/ span 10',
          null,
          '14 / span 8',
          '13/ span 10'
        ],
        gridRow: [null, null, 2, null, 2, 2],
        alignSelf: [null, null, 'center', null, 'start', 'start'],
        mt: ['4.3rem', null, null, null, '7.2rem', '7.2rem']
      }}
      headline={convertApiHtmlText(headline.html)}
      caption={caption}
    />
  );
};

const Description = ({ description }) => {
  return (
    <Paragraph
      sx={{
        gridColumn: [
          '1/13',
          null,
          '1/ span 14',
          null,
          '14/ span 10',
          '13 / span 8'
        ],
        fontFamily: 'body.normal',
        lineHeight: 1.35,
        letterSpacing: '-0.01em',
        fontSize: ['1.6rem', null, '1.7rem', null, '1.6rem', '1.7rem'],
        mt: ['4rem', null, '4.8rem', null, 0, 0],
        alignSelf: [null, null, null, null, 'end', 'end'],
        mb: [null, null, null, null, '2.4rem', '2.4rem']
      }}>
      {description}
    </Paragraph>
  );
};

const SectionBackground = () => {
  return (
    <ResponsiveImage
      images={[
        phonePortraitBg,
        null,
        tabletPortraitBg,
        null,
        tabletLandscapeBg,
        desktopBg
      ]}
      sx={{
        gridColumn: ['1/13', null, '1/25', null, '1/25', '1/25'],
        width: ['35.1rem', null, '81.1rem', null, '117rem', '141.6rem'],
        'img, source': {
          width: '100%'
        },
        position: 'absolute',
        top: ['1.1rem', null, '1.1rem', null, '1.2rem', '1.2rem'],
        left: '50%',
        transform: 'translate(-50%, 0)',
        zIndex: -1
      }}
    />
  );
};

const TopSpacer = () => (
  <GridSpacer
    height={[null, null, '9.9rem', null, '9.9rem', '9.9rem']}
    sx={{
      display: ['none', null, 'block', null, 'block', 'block']
    }}
  />
);
